import { http, Strategies } from '@lengoo/ui-core-service-http';
import ky from 'ky';

export const HTTP_CLIENT = http(Strategies.ky, {
  baseURL: '/api',
  retry: {
    limit: 5,
    methods: ['get'],
    statusCodes: [404],
  },
});

// TODO: AST-668
export const KY_HTTP_CLIENT = ky.create();
