import { z } from 'zod';

export const FEATURE_FLAGS_RESPONSE_SCHEMA = z.object({
  enableSummarize: z.boolean().optional(),
  enableQa: z.boolean().optional(),
  enableTranslate: z.boolean().optional(),
  enableGenerate: z.boolean().optional(),
  enableTranscribe: z.boolean().optional(),
});

export type FeatureFlagsResponse = z.infer<typeof FEATURE_FLAGS_RESPONSE_SCHEMA>;
