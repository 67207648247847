import { AUTH_CALLBACK_GUARD, AUTH_GUARD } from '@/@core/services/auth/auth';
import { qaRoute } from '@/modules/Qa/route';
import { summaryRoute } from '@/modules/Summary/route';
import { translationRoute } from '@/modules/Translation/route';
import { transcriptionRoute } from '@/modules/transcription/route';
import { redirectToDefaultRoute } from '@/router/navigationGuards';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import 'vue-router';

// type the route meta field
// see https://router.vuejs.org/guide/advanced/meta.html#TypeScript
declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean;
  }
}

export const homeRoute: RouteRecordRaw = {
  path: '/',
  name: 'App',
  component: () => import('@/@core/views/BaseView.vue'),
  children: [qaRoute, translationRoute, transcriptionRoute, summaryRoute],
  beforeEnter: redirectToDefaultRoute,
  meta: {
    requiresAuth: true,
  },
};

// @ts-expect-error - this is a valid route
const loginCallbackRoute: RouteRecordRaw = {
  path: '/callback',
  name: 'LoginCallback',
  beforeEnter: AUTH_CALLBACK_GUARD,
};

const notFoundRoute: RouteRecordRaw = {
  path: '/:pathMatch(.*)*',
  component: () => import('@/@core/views/NotFound.vue'),
};

const routes: RouteRecordRaw[] = [homeRoute, loginCallbackRoute, notFoundRoute];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(AUTH_GUARD);

export default router;
