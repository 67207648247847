import { setLocalMessages, t } from '@/i18n';
import { useSnackbarStore } from '@/stores/useSnackbarStore';
import { defineStore } from 'pinia';

interface Notification {
  type: 'success' | 'error' | 'info';
  message: string;
  timeout?: number;
}

await setLocalMessages('notifications');
await setLocalMessages('qa-notifications');

export const useNotificationStore = defineStore('notifications', () => {
  const snackbarStore = useSnackbarStore();
  const addNotification = (payload: Notification) => {
    snackbarStore.addSnackbar({
      message: payload.message,
      confirmationButtonText: t('SNACKBAR.got-it'),
      variant: payload.type,
      soft: true,
    });
  };

  const addInfoNotification = (message: string) => {
    addNotification({
      type: 'info',
      message,
    });
  };
  const addSuccessNotification = (message: string) => {
    addNotification({
      type: 'success',
      message,
    });
  };

  const addErrorNotification = (message: string) => {
    addNotification({
      type: 'error',
      message,
    });
  };

  return {
    addNotification,
    addErrorNotification,
    addInfoNotification,
    addSuccessNotification,
  };
});
