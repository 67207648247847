import { useQaResourcesStore } from '@/modules/Qa/stores/useQaResourcesStore';
import { translationRoute } from '@/modules/Translation/route';
import { useTranslationResourcesStore } from '@/modules/Translation/stores/useTranslationResourcesStore';
import { homeRoute } from '@/router/index';
import { useConfigStore } from '@/stores/useConfigStore';
import { NavigationGuard } from 'vue-router';

export async function withQaResources() {
  await useQaResourcesStore().getQaResources();
}

export async function withTranslationResources() {
  await useTranslationResourcesStore().getTranslationResources();
}

export async function withConfig() {
  const configStore = useConfigStore();
  if (!configStore.config) {
    await configStore.getConfig();
  }
}

const defaultRoute = translationRoute;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const redirectToDefaultRoute: NavigationGuard = (to, from) => {
  if (to.path === homeRoute.path) {
    return { path: defaultRoute.path };
  }
};
