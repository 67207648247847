import { HTTP_CLIENT } from '@/@core/services/api/httpClient';
import { authManager } from '@/@core/services/auth/auth';
import { User } from 'oidc-client-ts';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useUserStore = defineStore('user', () => {
  const user = ref<User | null>(null);

  async function fetch() {
    user.value = await authManager.getUser();
    if (user.value !== null) {
      HTTP_CLIENT.addBearerAuthentication(user.value.access_token);
    }
  }

  return {
    user,
    fetch,
  };
});
